import {Mongo} from 'meteor/mongo';

import {RestaurantServiceEnum, IRestParams} from 'shared/collections/Users';

type TRestConfig = {
	[key in RestaurantServiceEnum]?: IRestParams;
};
export const restConfig: TRestConfig = {
	[RestaurantServiceEnum.RKEEPER]: {
		isEnabled: true,
		authNeeded: true,
		authRequestNeeded: true,

		authFormFields: [
			{name: 'client_Id', title: 'Ваш client_Id', type: 'text'},
			{name: 'client_secret', title: 'Ваш client_secret', type: 'text'},
		],

		authRequestType: 'post',
		authRequestDataType: 'multipart/form-data',
		authRequestParams: {
			body: [
				{name: 'client_Id'},
				{name: 'client_secret'},
				{name: 'grant_type', value: 'client_credentials'},
				{name: 'scopes', value: 'orders'},
			],
		},
		authType: 'Token',
		tokenType: 'Bearer',
		tokenLifetime: 3500, //токен на час
		authUri: 'https://auth-delivery.ucs.ru/connect/token',

		settingsFormFields: [
			{
				name: 'restaurantId',
				title: 'ID заведения',
				type: 'text',
				tooltip:
					'ID ресторана можно взять из адресной строки браузера, на странице заведения',
			},
		],

		requiredAuthParams: {
			headers: {
				'Content-Type': 'multipart/form-data',
				Accept: '*/*',
			},
		},

		requiredParams: {
			headers: {
				'Content-Type': 'application/json',
				Connection: 'keep-alive',
			},
		},

		baseUri: 'https://delivery.ucs.ru/orders/api/v1',

		menuRequestType: 'get',
		menuPath: '/menu/view',
	},
	[RestaurantServiceEnum.QUICKRESTO]: {
		isEnabled: true,
		authNeeded: true,
		authRequestNeeded: false,
		authType: 'Basic',

		authFormFields: [
			{name: 'login', title: 'Логин', type: 'text'},
			{name: 'password', title: 'Пароль', type: 'password'},
		],

		requiredParams: {
			headers: {
				'Content-Type': 'application/json',
				Connection: 'keep-alive',
			},
		},

		settingsFormFields: [
			{
				name: 'restId',
				title: 'ID заведения',
				type: 'text',
				tooltip: 'ID ресторана можно взять из адресной строки в настройках ресторана',
			},
		],

		baseUri: 'https://{login}.quickresto.ru/platform/online/api',
		baseUriReplaces: ['login'],

		menuRequestType: 'get',
		menuPath: '/tree?moduleName=warehouse.nomenclature.dish',
		menuFilter: [
			{
				name: 'className',
				action: 'eq',
				value: 'ru.edgex.quickresto.modules.warehouse.nomenclature.dish.Dish',
			},
		],
	},
	[RestaurantServiceEnum.IIKO]: {
		isEnabled: true,
		authNeeded: true,
		authRequestNeeded: true,

		authFormFields: [{name: 'apiLogin', title: 'API - ключ', type: 'text'}],

		authRequestType: 'post',
		authRequestParams: {body: [{name: 'apiLogin'}]},
		authType: 'Token',
		tokenType: 'Bearer',
		tokenLifetime: 3500, //токен на час
		authUri: 'https://api-ru.iiko.services/api/1/access_token',

		settingsFormFields: [
			{
				name: 'organizationIds',
				title: 'ID заведения',
				type: 'text',
				tooltip: 'ID ресторана можно взять в "Настройки Cloud API / Организации',
			},
			{
				name: 'externalMenuId',
				title: 'ID внешнего меню',
				type: 'text',
				tooltip:
					'ID внешнего меню, можно взять из адресной строки браузера, на странице меню',
			},
			{
				name: 'priceCategoryId',
				title: 'ID цены категорий',
				type: 'text',
				tooltip:
					'ID внешнего меню, можно взять из адресной строки браузера, на странице меню',
			},
		],

		requiredAuthParams: {
			headers: {
				'Content-Type': 'application/json',
				Timeout: 10,
				Connection: 'keep-alive',
			},
		},

		requiredParams: {
			headers: {
				'Content-Type': 'application/json',
				Timeout: 20,
				Connection: 'keep-alive',
			},
		},

		baseUri: 'https://api-ru.iiko.services/api/2',

		menuRequestType: 'post',
		menuPath: '/menu/by_id',
	},
};

// todo: Сделать работу меню через базу
export interface IRestMenu {
	_id?: string;
	name: string;
	lastUpdate: Date;
	error?: boolean;
	createdAt: Date;
	isNeedUpdate?: boolean;
	updeteInterval?: number;
	items: IRestMenuItem[];
}

export interface IRestMenuItem {
	id?: number;
	name?: string;
	price?: string;
	imageUrls?: string[];
}

export const RestMenu = new Mongo.Collection<IRestMenu>('rest_menu');
